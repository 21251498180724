import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 30 min. `}<meta itemProp="cookTime" content="PT30M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Whole grain spaghetti, 1 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 6 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 6 cloves chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Gochujang paste, 1/4 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Honey, 1/4 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Rice vinegar, 1/4 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Black pepper, to taste</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Large pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Medium pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Colander</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Chop garlic</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Cook spaghetti in medium pot. When done, drain and reserve 1 cup of the pasta water.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`While spaghetti is cooking, melt 4 tbs. butter in large pot.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add garlic and cook until just soft.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add gochujang, honey, and rice vinegar. Simmer until reduced.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add spaghetti and remaining butter to the sauce. Stir until butter is melted.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add pasta water in small amounts and stir in until sauce is thinned out to taste.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add black pepper to taste.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      